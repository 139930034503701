export default {
  methods: {
    confirmUnassignment(callback) {
      var do_not_notify = false
      this.$dialog.confirm('<div class="row justify-content-center"> \
          <div class="col-auto"><h5>This visit has an assigned job</h5></div> \
          <div class="col-auto"><h6>Why do you want to unassign it?</h6></div> \
<div class="col-auto m-4"> \
<select class="custom-select" name="reason"> \
  <option value="another_cleaner">Different cleaner requested</option> \
  <option value="rescheduled">The service has been rescheduled</option> \
  <option value="cleaner_declined">The cleaner declined the service</option> \
</select> \
</div> \
          <div class="col-auto"><h5>How do you want to unassign it?</h5></div> \
<div class="col-auto m-4"><div class="form-check"> \
  <input class="form-check-input" type="radio" name="confirmOptions" id="do_not_notify" value="do_not_notify" checked> \
  <label class="form-check-label" for="do_not_notify">Just Unassign</label> \
</div></div> \
<div class="col-auto m-4"><div class="form-check"> \
  <input class="form-check-input" type="radio" name="confirmOptions" id="notify" value="notify"> \
  <label class="form-check-label" for="notify">Unassign & Notify</label> \
</div></div> \
</div>', { html: true, cancelText: 'Do not unassign' })
      .then(() => {
        const confirmOptions = $('input[name="confirmOptions"]:checked').val()
        const reason = $('select[name="reason"]').val()
        if (confirmOptions == 'do_not_notify') {
          do_not_notify = true
        }
        let args = { notify: !do_not_notify, reason: reason }
        callback(args)
      })
    },
    confirmWithNotificaton(callback) {
      var do_not_notify = false
      this.$dialog.confirm('<div class="row justify-content-center"> \
          <div class="col-auto"><h5>This job has an assigned cleaner</h5></div> \
          <div class="col-auto"><h5>How do you want to unassign it?</h5></div> \
<div class="col-auto m-4"><div class="form-check"> \
  <input class="form-check-input" type="radio" name="confirmOptions" id="do_not_notify" value="do_not_notify" checked> \
  <label class="form-check-label" for="do_not_notify">Just Unassign</label> \
</div></div> \
<div class="col-auto m-4"><div class="form-check"> \
  <input class="form-check-input" type="radio" name="confirmOptions" id="notify" value="notify"> \
  <label class="form-check-label" for="notify">Unassign & Notify</label> \
</div></div> \
</div>', { html: true, cancelText: 'Back' })
      .then(() => {
        const confirmOptions = $('input[name="confirmOptions"]:checked').val()
        if (confirmOptions == 'do_not_notify') {
          do_not_notify = true
        }
        callback(do_not_notify)
      })
    }
  }
}
